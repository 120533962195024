import { Link } from "gatsby"
import "pure-react-carousel/dist/react-carousel.es.css"
import React from "react"
import IconLayers from "../images/layers.svg"
import IconShoppingBag from "../images/shopping-bag.svg"
import IconTarget from "../images/target.svg"

const SalesUseCase = () => (
  <div className="flex-1 flex flex-col gap-8 items-center py-8 bg-gradient-to-b from-gray-900 to-black rounded-lg">
    <div className="bg-gray-900 border border-gray-500 rounded-lg p-3">
      <img src={IconShoppingBag} />
    </div>
    <div className="flex flex-col gap-3">
      <div className="text-2xl font-semibold">Tactic for Sales</div>
      <ul className="text-left list-disc text-lg list-inside">
        <li>Find who to call, when, why</li>
        <li>Collaborate on account plans</li>
        <li>Identify trends and talking points</li>
        <li>Perfect your CRM data</li>
      </ul>
    </div>
    <Link
      to="/sales"
      className="rounded-lg bg-white border border-medium-grey px-4 py-2 leading-0 text-black hover:text-lilac transition"
    >
      Learn More
    </Link>
  </div>
)
const MarketingUseCase = () => (
  <div className="flex-1 flex flex-col gap-8 items-center py-8 bg-gradient-to-b from-gray-900 to-black rounded-lg">
    <div className="bg-gray-900 border border-gray-500 rounded-lg p-3">
      <img src={IconTarget} />
    </div>
    <div className="flex flex-col gap-3">
      <div className="text-2xl font-semibold">Tactic for Marketing</div>
      <ul className="text-left list-disc text-lg list-inside">
        <li>Build accurate targeted audiences</li>
        <li>Increase inbound conversion</li>
        <li>Launch campaigns with confidence</li>
        <li>Improve cost per acquisition</li>
      </ul>
    </div>
    <Link
      to="/marketing"
      className="rounded-lg bg-white border border-medium-grey px-4 py-2 leading-0 text-black hover:text-lilac transition"
    >
      Learn More
    </Link>
  </div>
)
const RevOpsUseCase = () => (
  <div className="flex-1 flex flex-col gap-8 items-center py-8 bg-gradient-to-b from-gray-900 to-black rounded-lg">
    <div className="bg-gray-900 border border-gray-500 rounded-lg p-3">
      <img src={IconLayers} />
    </div>
    <div className="flex flex-col gap-3">
      <div className="text-2xl font-semibold">Tactic for RevOps</div>
      <ul className="text-left list-disc text-lg list-inside">
        <li>Clean data into viable accounts</li>
        <li>Expand market with new segments</li>
        <li>Increase all-bound conversion rate</li>
        <li>Budget your hiring</li>
      </ul>
    </div>
    <Link
      to="/revenue-operations"
      className="rounded-lg bg-white border border-medium-grey px-4 py-2 leading-0 text-black hover:text-lilac transition"
    >
      Learn More
    </Link>
  </div>
)

const UseCases = ({ hideSection }) => (
  <section className="py-12 px-5 md:py-24 text-white" id="use-cases">
    <div className="text-center max-w-3xl mx-auto flex flex-col gap-4 mb-12">
      <div className="font-bold text-3xl md:text-5xl">{hideSection && "More "}Use Cases</div>
      {!hideSection && (
        <div className="text-medium-grey text-lg md:text-xl">
          Prospecting. Developing effective content. Refining and targeting your
          most valuable marketing segments. Moving your research to Tactic gives
          you more actionable information for these projects than you could ever
          manually compile.
        </div>
      )}
    </div>
    <div className="container flex flex-col lg:flex-row text-center mx-auto gap-2">
      {hideSection !== "sales" && <SalesUseCase />}
      {hideSection !== "marketing" && <MarketingUseCase />}
      {hideSection !== "revops" && <RevOpsUseCase />}
    </div>
  </section>
)

export default UseCases
