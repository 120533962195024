import React from "react"

const PersonaCard = ({ image, jobTitle, quote1, quote2 }) => (
  <div className="group text-center relative">
    <h3 className="text-xl text-white">{jobTitle}</h3>
    <img
      src={image}
      className="opacity-75 group-hover:opacity-100 transition duration-500 my-8"
    />
    <div className="absolute top-56 -left-4 opacity-0 group-hover:opacity-100 transition duration-500 flex flex-col gap-2">
      <div className="bg-white rounded-lg w-48 text-sm p-2">{quote1}</div>
      <div className="bg-white rounded-lg w-48 text-sm p-2">{quote2}</div>
    </div>
  </div>
)

export default PersonaCard
