import React from "react"

const BenefitCard = ({ icon, heading, body }) => (
    <div className="flex-1 flex flex-col gap-4 rounded-xl border border-lilac p-6 bg-gray-900">
      {icon}
      <h3 className="text-lilac text-2xl md:text-3xl font-semibold">{heading}</h3>
      <p className="text-medium-grey">{body}</p>
    </div>
  )

export default BenefitCard