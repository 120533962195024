import React from "react"
import HeadlessCTA from "../components/headlessCTA"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"
import logoDoodad from "../images/logo-doodad.png"
import CTA from "../components/cta"
import BenefitCard from "../components/BenefitCard"
import FeatureCard from "../components/FeatureCard"
import { Link } from "gatsby"

import { IconMessage2, IconSortAscending, IconUsers } from "@tabler/icons"

import forSDRs from "../images/for-sdrs.png"
import forAEs from "../images/for-account-executives.png"
import forCROs from "../images/for-cros.png"
import PersonaCard from "../components/PersonaCard"

import HeroImage from "../images/use-cases/sales.png"
import Feature1 from "../images/use-cases/sales 1.png"
import Feature2 from "../images/use-cases/sales 2.png"
import Feature3 from "../images/use-cases/sales 3.png"
import UseCases from "../components/UseCases"

const SalesLanding = () => {
  return (
    <Layout variant="useCase">
      <SEO
        title="Automate your research. Generate more pipeline."
        description="Increase pipeline, reduce ramp and save millions of hours of manual research and data entry by automating company and prospect research."
      />
      <div className="max-w-screen overflow-x-hidden relative">
        <div className="container mx-auto px-4 sm:px-6 mb-12 xl:mb-64">
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-24 py-24 items-center">
            <div>
              <Tag>Tactic for Sales</Tag>
              <h1
                className="font-extrabold text-white text-5xl md:text-7xl mt-12 mb-4"
                style={{
                  mixBlendMode: "overlay",
                  fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
                }}
              >
                Automate your research. Generate more pipeline.
              </h1>

              <h2 className="text-white text-xl mb-8">
                Increase pipeline, reduce ramp and save millions of hours of
                manual research and data entry by automating company and
                prospect research.
              </h2>

              <HeadlessCTA />
            </div>
            <img
              src={HeroImage}
              className="xl:absolute xl:top-24 xl:left-1/2 shadow-violet-400/50 shadow-2xl rounded-lg"
              style={{ maxHeight: "650px" }}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <div className="h-px flex-1 bg-salmon"></div>
        <div className="rounded-full border border-salmon text-salmon py-1 px-4 leading-none">
          Problems we solve for Sales &darr;
        </div>
        <div className="h-px flex-1 bg-salmon"></div>
      </div>

      <img
        src={logoDoodad}
        className="absolute mt-36"
        style={{ maxHeight: "650px" }}
      />

      <div className="container p-4 md:p-24 mx-auto flex flex-col lg:flex-row gap-12 text-white my-24 bg-white/10 rounded-xl backdrop-blur-lg border border-white/20">
        <h2 className="font-bold text-4xl md:text-6xl flex-1 leading-tight z-10">
          Your sales targets get higher, but your data stays flat
        </h2>
        <div className="text-xl flex-1 z-10 md:pt-48">
          <p className="mb-4">
            Hitting a higher and higher quota is stressful, and you aim to get
            there with strategic, insightful selling. Unfortunately, you spend
            all day on pre-call research, prioritising accounts, finding new
            opportunities, and not spending enough time speaking to actual
            customers.
          </p>
          <p className="text-lilac">
            Tactic enables you to make decisions with 10x the insight across the
            sales cycle in just a few clicks. You'll prospect faster, engage
            with more insight, and close bigger deals.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <div className="flex flex-col gap-4 lg:text-center max-w-2xl mx-auto mb-12">
          <h2 className="font-bold text-4xl md:text-5xl flex-1 leading-tight text-white z-10">
            Get your targets' attention with unparalleled relevancy
          </h2>
          <p className="text-medium-grey text-xl">
            Even with the best people skills, if you don't have relevant
            insights to call the right people at the right time, with the right
            message, you won't land the sale.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-2">
          <BenefitCard
            icon={
              <IconSortAscending size={48} stroke={1.5} className="text-blue" />
            }
            heading="Filter and prioritise accounts using natural language"
            body="Cross-search hyperspecific queries to pinpoint your ideal customers, even when opening up prospecting to new segments and industries."
          />
          <BenefitCard
            icon={<IconMessage2 size={48} stroke={1.5} className="text-blue" />}
            heading="Find quotes and interviews for powerful talking points"
            body="Surface snippets from news and web content (including PDFs), highlighting key hooks and triggers for you to catch prospects' attention."
          />
          <BenefitCard
            icon={<IconUsers size={48} stroke={1.5} className="text-blue" />}
            heading="Collaborate across sales, marketing and success"
            body="Share your Tactic projects with sales and business development colleagues, so you can keep your prospects and leads managed and up-to-date in one place."
          />
        </div>
        <Link
          to="/use-case/exec-quotes/"
          className="text-center text-blue hover:text-white"
        >
          Example data point: finding quotes from CEOs, CTOs, and other C-Level
          execs &rarr;
        </Link>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6 my-48">
        <div className="flex flex-col gap-4 lg:text-center max-w-2xl mx-auto">
          <h2 className="font-bold text-5xl flex-1 leading-tight text-white">
            Tactic is for
          </h2>
        </div>
        <div className="flex flex-col lg:flex-row gap-24 mx-auto">
          <PersonaCard
            image={forSDRs}
            jobTitle="Sales Development Reps"
            quote1="“I don't know who to call first.”"
            quote2="“I relied on my colleagues to help me prioritise my work.”"
          />
          <PersonaCard
            image={forCROs}
            jobTitle="CROs/Sales Leaders"
            quote1="“My team weren't getting enough pipeline from ICP accounts.”"
            quote2="“We couldn't even get on the phone with prospects before our competitors closed with them.”"
          />
          <PersonaCard
            image={forAEs}
            jobTitle="Account Executives"
            quote1="“I struggled to find high fit opportunities.”"
            quote2="“I wasn't confident about prospecting and account planning.”"
          />
        </div>
      </div>

      <div className="flex flex-col gap-9 container mx-auto px-4 sm:px-6">
        <h2 className="text-white text-5xl font-bold mb-12 text-center">
          Top features for Sales teams
        </h2>
        <div className="flex flex-col gap-9">
          <FeatureCard
            heading="A search engine for everything"
            body="You can finally close all your browser tabs, because Tactic will search for insights across the whole web including annual company reports, T&Cs, 'about us' pages, LinkedIn, Zoominfo, nestled PDFs, job posts and more."
            direction="left"
            image={Feature1}
          />
          <FeatureCard
            heading="Adjustable reports"
            body="You can be agile with your data, curating reports from your Tactic results in just a few clicks to have the relevant people and keyword insights to hand while you're on a sales call or designing your outbound messaging."
            direction="right"
            image={Feature2}
          />
          <FeatureCard
            heading="Set up the next steps"
            body="You can set up Tactic to automatically trigger account prioritisation within Salesforce, email campaigns and updates to your call list based on any updates to your insights when you re-run your project."
            direction="left"
            image={Feature3}
          />
        </div>
      </div>
      <UseCases hideSection="sales" />
      <CTA />
    </Layout>
  )
}

export default SalesLanding
