import React from "react"

const FeatureCard = props => (
  <div
    className="flex flex-col xl:flex-row gap-8 xl:gap-24
    items-center rounded-lg bg-lilac
    p-6 lg:py-36 lg:px-24 gradient-background"
  >
    {props.direction === "left" && (
      <img src={props.image} className="max-h-96 z-10" />
    )}
    <div className="flex-1 flex flex-col gap-8 relative">
      <h3 className="text-white text-3xl lg:text-5xl font-semibold">
        {props.heading}
      </h3>
      <p className="text-light-grey lg:text-lg font-light">{props.body}</p>
    </div>
    {props.direction === "right" && (
      <img src={props.image} className="max-h-96 z-10" />
    )}
  </div>
)

export default FeatureCard
